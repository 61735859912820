<template>
  <div>
    <p :class="index === 0 && 'red'" class="f-17" v-for="(item,index) in data">{{ item }}</p>
    <!-- start form -->
    <the-form v-if="show_form"/>
  </div>
</template>

<script>
import TheForm from "@/components/theForm";
export default {
  components: {TheForm},
  data:() => ({
    data: [" ... جاري الإتصال بقاعدة البيانات."],
    show_form: false,
  }),
  methods: {

  },
  mounted() {
    setTimeout( () => {
      this.data.push('يوجد 3 فرص للعمل')
    },2000)
    setTimeout( () => {
      this.data.push(' .... لا يوجد تسجيلات سابقه من نفس الشخص')
    },3500)
    setTimeout( () => {
      this.data.push('مبروك انت مؤهل للعمل')
    },5000)
    setTimeout( () => {
      this.data = []
      this.show_form = true;
    },6000)
  }
}
</script>

<style scoped>
 p {
   text-align: center;
 }
</style>